import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
`;

export const BGImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.3;
`;

export const NotificationWrapper = styled.div`
  margin-top: 1.6rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`;

export const SocialWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 1.6rem;
    margin-top: 4rem;

    @media (max-width: ${theme.breakpoints.small}) {
      margin-top: 2.4rem;
      gap: 0.8rem;
    }
  `}
`;

export const SocialItem = styled(motion.a)`
  ${({ theme }) => css`
    color: ${theme.colors.title};
    transition: color 0.2s ease-in-out;
    cursor: pointer;

    svg {
      width: 2.4rem;
      height: 2.4rem;
    }

    &:hover {
      color: ${theme.colors.highlightColor};
    }

    @media (max-width: ${theme.breakpoints.small}) {
      svg {
        width: 2rem;
        height: 2rem;
      }
    }
  `}
`;

export const Section = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    gap: 12rem;

    @media (max-width: ${theme.breakpoints.small}) {
      flex-direction: column-reverse;
      gap: 6.4rem;
    }
  `}
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  width: 100%;
  max-width: 100rem;
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 3.2rem;

    @media (max-width: ${theme.breakpoints.small}) {
      gap: 1.6rem;
      margin-bottom: 4rem;

      > :last-child {
        display: none;
      }
    }
  `}
`;

export const ImageWrapper = styled.div`
  ${({ theme }) => css`
    display: none;
    margin-bottom: 3.2rem;
    width: 12rem;
    height: 12rem;
    border-radius: 100%;
    overflow: hidden;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      filter: saturate(1.2);
    }

    @media (max-width: ${theme.breakpoints.normal}) {
      /* display: block; */
      width: 8rem;
      height: 8rem;
    }
  `}
`;
